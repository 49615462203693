import '../css'

addEventListener('turbo:load', function() {
  let search = document.querySelector('#search-auth input[name="q"]')
  if (search && search.value != '')
    document.querySelector('#search-auth input[name="commit"]').click()
})

addEventListener('turbo:frame-load', function() {
  let selects = document.querySelectorAll('select[name="user[role]"][class*="is-"]')
  if (selects.length > 0) {
    setTimeout(function() {
      selects.forEach(el => el.classList.remove('is-valid', 'is-invalid'))
    }, 2000)
  }
})

$(document).on('change', '.edit_user select', function() {
  $(this).parents('.edit_user').find('input[name="commit"]').trigger('click')
})
