$(document).on('change', '#reminder_day_of_month_ary, #reminder_month_ary, #reminder_day_of_week_ary', function() {
  showDescription()
})

const showDescription = function() {
  let days = selectedOptions('day_of_month')
  let months = selectedOptions('month')
  let wdays = selectedOptions('day_of_week')

  let desc = ''
  if (wdays.length > 0 && wdays.length < 7 && days.length == 0)
    desc += `Every ${arrayToSentence(wdays)} `
  desc += daysToSentence(days, wdays) + monthsToSentence(months, days) + wdaysToSentence(wdays, days)

  $('#reminder-description').text(desc)
}

const selectedOptions = function(name) {
  return $(`#reminder_${name}_ary option:selected`).get().map(option => option.textContent)
}

const arrayToSentence = function(ary, connector = 'and') {
  if (ary.length == 1)
    return ary[ary.length - 1]
  else if (ary.length == 2)
    return ary.join(` ${connector} `)
  else
    return ary.slice(0, -1).map(i => `${i}, `).concat(`${connector} `, ary[ary.length - 1]).join('')
}

const daysToSentence = function(days, wdays) {
  if (days.length > 0)
    return `Day${days.length > 1 ? 's' : ''} ${arrayToSentence(days)} `
  else if (wdays.length == 0 || wdays.length == 7)
    return 'Every day '
  else
    return ''
}

const monthsToSentence = function(months, days) {
  if (months.length > 0 && months.length < 12)
    return `of ${arrayToSentence(months)} `
  else if (days.length > 0)
    return 'of every month '
  else
    return ''
}

const wdaysToSentence = function(wdays, days) {
  if (wdays.length > 0 && wdays.length < 7 && days.length > 0) {
    let plural = wdays.map(d => `${d}s`)
    return `that are ${arrayToSentence(plural, 'or')}`
  } else
    return ''
}
