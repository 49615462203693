import '../css'
import './fontawesome'

import { Stimulus, Controller } from './stimulus'

Stimulus.register('select2', class extends Controller {
  connect() {
    $(this.element).select2({
      theme: 'bootstrap4'
    }).trigger('change') // Trigger description
  }
})

addEventListener('turbo:before-cache', () => {
  $('select[data-controller="select2"]').each(function() {
    if ($(this).data('select2')) $(this).select2('destroy')
  })
})
