import '@hotwired/turbo'

import './global/jquery'

import 'bootstrap'
import 'bootswatch/dist/spacelab/bootstrap.css'

import select2 from 'select2'
import 'select2/dist/css/select2.css'
import 'select2-bootstrap4-theme/dist/select2-bootstrap4.css'
select2(window, $)

import '#/application/js'
import '#/work_orders/js'
import '#/admin/js'
